/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import waly from './img/waly.png';
import './App.css';

import * as firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/database';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { css } from "@emotion/core";
import ClockLoader from "react-spinners/ClockLoader";
require('dotenv').config();
//loader css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

function App() {
  const [loader, setLoading] = useState(true);

  useEffect(() => {
    handleSignIn();
  }, []);

  function handleSignIn() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      const url = new URL(window.location.href);
      const c = url.searchParams.get("email");
      let email = c;
      if (email) {
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(function (result) {
            initApp()
          })
          .catch(function (error) {
            setLoading(false)
            window.location.href= "https://www.waly.io/retry"
          });
      }
    } else {
        setLoading(false)
        window.location.href= "https://www.waly.io/"
    }
  }

  function initApp() {
      firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
            let finalUData = JSON.parse(JSON.stringify(user));
            try {
                window.postMessage({
                    type: "ONLOAD",
                    userToken: finalUData.stsTokenManager.accessToken,
                    refreshToken: finalUData.stsTokenManager.refreshToken
                }, "https://web.waly.io/")
                window.onmessage = (event: any) => {
                    if (event?.data.type === "OFFLOAD") {
                        if (event?.data.success === true) {
                            setLoading(false);
                            window.location.href = "https://www.waly.io/welcome"
                        } else if (event?.data.success) {
                            setLoading(false)
                            window.location.href = "https://www.waly.io/retry"
                        }
                    }
                }
            } catch (error) {
                setLoading(false)
                window.location.href = "https://www.waly.io/retry"
            }
          }
      });
  }

  return (
    newFunction()
  );

  function newFunction() {
    return <div className='App flex-row align-items-center body'>
      <Container>
        <Row>
          <Col>
            <img src={waly} alt={"waly"} />
          </Col>
        </Row>
        {loader === true &&
          <Row>
            <Col>
              <br /><br /><span className="text-auth">authenticating ...</span>
            </Col>
          </Row>}
        <div className="sweet-loading loader">
          <ClockLoader
            css={override}
            size={96}
            color={"#ffffff"}
            loading={loader}
          />
        </div>
      </Container>
    </div >;
  }
}

export default App;